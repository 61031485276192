// Here you can add other styles
.amplify-tabs {
  display: none !important;
}

.fontBold {
  font-weight: bold;
}

.rows_gg{
  padding-top: 15px;
  margin-left: 10px;
  background-color:rgb(236, 236, 204);
  border-bottom: 1px solid #aaa;
}

.rows_ggg{
  padding-top: 15px;
  margin-right: 10px;
  background-color:rgb(252, 233, 233);
  border-bottom: 1px solid #aaa;
}

.formTrim{
  padding-left: 0 !important;
  padding-right: 10px !important;
  float: left !important;
}

i.glyphicon { display: none; }
.btn-add { margin-top: 10px; }
.btn-add::after { content: 'Add'; margin-top: 10px; }
.array-item-move-up::after { content: 'Up'; }
.array-item-move-down::after { content: 'Down'; }
.array-item-remove::after { content: 'Remove';  }
.field-array > .row{ 
  margin-left: 0!important;
  margin-right: 0!important;
  display: block;
  flex: content;
}
.array-item > .col-xs-9 {
  width: 84%;
  float: left;
}
.array-item > .col-xs-3 {
  margin-left: 1%;
  width: 15%;
  float: left;
}

.array-item > .col-xs-9 > .field-object > fieldset > .field-string {
  width: 30% !important;
  margin-left: 1%;
  float: left;
}

.array-item > .col-xs-9 > .field-object > fieldset > .field-integer {
  width: 15% !important;
  margin-left: 1%;
  float: left;
}

.array-item > .col-xs-9 > .field-object > fieldset > .field-number {
  width: 15% !important;
  margin-left: 1%;
  float: left;
}

.field-array-of-object > .array-item-list > .array-item {
  min-height: 100px;
}

.field-array-of-object > .array-item-list > .array-item > .col-xs-3 {
  margin-left: 1%;
  width: 15%;
  float: left;
  padding-top: 25px;
}

.form-custom {
  clear: both;
}


.array-item-add {
  clear: both;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 250px;
  height: 250px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin-top: 35%;
  margin-left: 85%;
  box-sizing: border-box;
  border: 32px solid #ccc;
  border-color: #aaa transparent #aaa transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.fontSize25 {
  font-size: 25px;
}

.customTabContent {
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  padding: 20px;
  border-color: darkgray;
}

.hidden {
  display: none;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #39f;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.dataDiv > .table th, .table td  {
  vertical-align: middle;
}

.abGroupVariants {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}
.iapCollector {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}

.rangeItems > .field-array > .array-item-list {
  display: flex !important;
}

.array-item > .col-xs-9 > .rule > fieldset > .field-string {
  width: 15% !important;
}

.rule > select.form-control[size], select.form-control[multiple] {
  width: 50%;
}

.teamLogo{
  max-height: 60px;
}

.teamChats{
  max-height: 600px;
  overflow-y: scroll;
}

.teamChatButtons {
  float: right;
}

.teamChatMessage {
  margin-top: 1px;
  font-size: 12px;
  display: inline-block;
}

fieldset[id^=root_teamProgress_economyKeys_] {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}

fieldset[id^=root_levelRaceBotRates_] {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}

fieldset[id^=root_journeyPass_] {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}

fieldset[id^=root_journeyPass_freeRewards_] {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}

fieldset[id^=root_journeyPass_premiumRewards_] {
  background-color: #ccc;
  padding: 10px;
  border: solid 1px #ccc;
}


.array-item > .col-xs-9 > .rule > fieldset > .ddd {
  width: 95% !important;
}

.nav-pills {
  border-bottom: 10px solid !important;
  margin-left: -15px;
}

.nav-pills > li {
  display: block;
  padding: 0.5rem 1rem;
  border: 1px solid darkgray;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-pills > li.active {
  display: block;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  color: #000;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-weight: bold;
}

fieldset[id^=root_chainRewards_] {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}

fieldset[id^=root_offerList_] {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}

fieldset[id^=root_bundles_] {
  background-color: #eee;
  padding: 10px;
  border: solid 1px #ccc;
}